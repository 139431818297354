export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const AUTH_BASE_PATH = '/auth/v1';
export const SEARCH_BASE_PATH = '/search/v1/1688';
export const SEARCH_BASE_PATH_COMMON = '/search/v1/common';
export const SEARCH_BASE_PATH_RUS = '/search/v1/rus';
export const TC_CABINET_BASE_PATH = '/cabinet/v1/tc';
export const CUSTOMER_CART_BASE_PATH = '/cabinet/v1/customer/cart';
export const CUSTOMER_ORDERS_BASE_PATH = '/cabinet/v1/customer/orders';
export const CUSTOMER_DICTIONARIES_PATH = '/cabinet/v1/customer/dictionary';
export const PROFILE_BASE_PATH = '/cabinet/v1/profile';
