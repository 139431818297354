import { Typography, Flex, Space} from 'antd';
import { Link } from 'react-router-dom';

const {Title, Text} = Typography;

const ProductInfoContainer = ({product = {}}) => {
    const {shopInfo = {}, deliveryInfo = {}} = product;
    const {shop_name, shop_url, seller_login_id, seller_user_id, seller_member_id} = shopInfo;
    const {location, unit_weight} = deliveryInfo;

    return(
        <Flex vertical> 
            <Title>Магазин</Title>
            <Link to={`/1688/factories/${seller_member_id}`}>{shop_name}</Link>
            <Title>Поставка</Title>
            <Text>{location}</Text>
            <Space>
                <Text strong>Вес:</Text>
                <Text>{unit_weight}</Text>
            </Space>
        </Flex>
    )
}

export default ProductInfoContainer;