import React from 'react';
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";

import {Outlet} from "react-router-dom";


const Page1688 = () => {
    return(
        <Outlet />
    );
}

export default Page1688;
