import React,{useState} from 'react';
import  './SearchContent.less'
import SortController from "./SortController";
import SearchProductResultContent from "./SearchProductResultContent";
import {useSelector} from "react-redux";
import {SearchTypes} from "../../../constants/enums";
import SearchFactoryResultContent from "./SearchFactoryResultContent";
import { Flex } from "antd";


const Search1688Content = () => {
    const searchType = useSelector(state => state.search1688.searchType)

    const searchResultContentRenderer = () => {
        if(searchType === SearchTypes.PRODUCTS)
            return <SearchProductResultContent />
        else
            return <SearchFactoryResultContent />
    }

    return(
        <Flex vertical className='search-layout-content'>
            <SortController />
            {searchResultContentRenderer()}
        </Flex>
    );
}

export default Search1688Content;
