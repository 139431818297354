import React from 'react'
import './ProductViewer.less'
import { Card, Col, Space, Typography, Flex } from 'antd';
import { Link } from 'react-router-dom';

const { Text, Title} = Typography;
const { Meta } = Card;


const ProductViewer = ({product = {}}) => {
    const {itemId, title, originalTitle,
        img, price = 0, productUrl,
        quantityBegin, quantityPrices = [],
        shopInfo = {},
        deliveryInfo = {}, saleInfo = {},
    } = product;
    const fabricusUrl = `/1688/products/${itemId}`;

    const priceRenderer = () => {
        return (
            <Flex vertical className='product-price-container'>
                <Title level={3} className='product-price'>{price.toLocaleString('zh-CN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: "CNY"
                    })}
                </Title>
                <Text className="quantity-title">Мин. кол-во: {quantityBegin}</Text>
            </Flex>
        )
    }

    const descriptionRenderer = () => {
        const {companyName='', shopRepurchaseRate, bizType,
            soreInfo = {}} = shopInfo;
        const {gmv30days='', saleQuantity = 0, ordersCount = 0} = saleInfo;
        const {weight, suttleWeight, areaFrom = []} = deliveryInfo;

        return(
            <div className='product-description-wrapper'>
                <div className='product-caption'>
                    <a href={fabricusUrl} target='_blank' rel="noreferrer">{title}</a>
                    <div>
                        <a className='original' href={productUrl} target='_blank' rel="noreferrer">{originalTitle} -&gt; 1688.com</a>
                    </div>
                </div>
                <div className='product-detail-container'>
                    <div className='product-detail'>
                        <h4>Продавец</h4>
                        <div className='product-detail-context'>
                            <p>{companyName}</p>
                            <p>Повторные заказы: {shopRepurchaseRate}</p>
                            <p>{bizType}</p>
                        </div>
                    </div>
                    <div className='product-detail'>
                        <h4>Доставка</h4>
                        <div className='product-detail-context'>
                            {areaFrom.map((area, index) => (
                                <p key={index} className="price-range">
                                    {area}
                                </p>
                            ))}
                            <p>Вес: {weight}</p>
                        </div>
                    </div>
                    <div className='product-detail'>
                        <h4>Продажи</h4>
                        <div className='product-detail-context'>
                            <p>Последние 30 дн.: {gmv30days}</p>
                            <p>Продано кол-во: {saleQuantity}</p>
                            <p>Заказов: {ordersCount}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


        return(
            <Col span={6} className='product-viewer-wrapper'>
                <Link to={`/1688/products/${itemId}`}>
                    <Card
                        hoverable
                        style={{ width: 240 }}
                        className='product-viewer-card'
                        cover={<img alt={title} src={img} />}
                        key={itemId+"-card"}
                    >

                        <Meta key={itemId+"-meta"} title={title} description={''} className='product-viewer-card-meta'/>

                        <Space direction="horizontal" className='product-viewer-card-price'>
                            {priceRenderer()}
                        </Space>
                
                    </Card>
                </Link>
            </Col>
        )
}

export default ProductViewer;

