import { Descriptions, Flex, Space, Typography, Tabs } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import ShopProducts from "./ShopProducts"


const FactoriesView = ({factory = {}}) => {
    const {memberId, companyName='', locationStr='', companyId, tpYear, isSuperFactory, retentionRate, factoryProps=[], shopRatings=[]} = factory;

    const getPropItems = () => {
        return factoryProps.map((prop, index) => {
            const label = prop.name;
            const children = prop.value; 
            return(
                {
                    'key': index,
                    'label': label,
                    'children': children
                }
            )
        })
    }
    const propItems = getPropItems();

    const getRatingItems = () => {
        return shopRatings.map((prop, index) => {
            const label = prop.title;
            const children = prop.score; 
            return(
                {
                    'key': index,
                    'label': label,
                    'children': children
                }
            )
        })
    }
    const ratingItems = getRatingItems();


    return(
        <Tabs defaultActiveKey="1" items={[
            {
                key : '1',
                label: 'О магазине',
                children: 
                    <Space direction="vertical"> 
                        <Space size={"large"}>
                            <Title>{companyName}</Title>
                            <Paragraph copyable title="ID компании">{memberId}</Paragraph>
                        </Space>
                        <Flex justify="end">
                            <Link to={`https://sale.1688.com/factory/card.html?__existtitle__=1&__removesafearea__=1&memberId=${memberId}&aHdkaW5n_isCentral=true&aHdkaW5n_isGrayed=false&aHdkaW5n_isUseGray=true`}>Страница компании на 1688.com</Link>
                        </Flex>
                        <Descriptions bordered title="О компании" items={propItems} />
                        <Descriptions bordered title="Рейтинг" items={ratingItems} />
                    </Space>
            },
            {
                key : '2',
                label: 'Товары магазина',
                children: 
                        <ShopProducts />
            }
        ]}
        />
    )
}

export default FactoriesView;