import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {message, notification} from 'antd';
import {
    addCompanyUser, deleteCustomerCompanyUsers,
    getMyCompany,
    getMyCompanyUsers,
    saveMyCompany
} from "../../../services/apiService";

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        companyDialogVisible: false,
        companyUserDialogVisible: false,
        myCompany: {},
        loading: false,
        companyUsers: [],
        companyUsersLoading: false
    },
    reducers: {
        setCompanyDialogVisible: (state, {payload}) => {
            state.companyDialogVisible = payload;
        },
        setCompanyUserDialogVisible: (state, {payload}) => {
            state.companyUserDialogVisible = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(profileMyCompanyAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(profileMyCompanyAsync.fulfilled, (state, action) => {
                state.myCompany = action.payload;
                state.loading = false;
            })
            .addCase(profileCompanyUsersAsync.pending, (state, action) => {
                state.companyUsersLoading = true;
            })
            .addCase(profileCompanyUsersAsync.fulfilled, (state, action) => {
                const {content} = action.payload;
                state.companyUsers = content;
                state.companyUsersLoading = false;
            })
            .addCase(profileSaveMyCompanyAsync.fulfilled, (state, action) => {
                state.myCompany = action.payload;
                state.companyDialogVisible = false;
                notification.success({
                        message: 'Организация',
                        description:
                            'Данные организации успешно сохранены.',
                    })
            })
            .addCase(profileSaveMyCompanyAsync.rejected, (state, action) => {
                notification.error({
                    message: 'Организация',
                    description:
                        'Ошибка сохранения, попробуйте еще раз.',
                })
            })
            .addCase(addCompanyUserAsync.fulfilled, (state, action) => {
                state.companyUsers = [
                    action.payload,
                    ...state.companyUsers
                ];
                state.companyUserDialogVisible = false;
                notification.success({
                        message: 'Пользователь',
                        description:
                            'Пользователь успешно добавлен.',
                    })
            })
            .addCase(addCompanyUserAsync.rejected, (state, action) => {
                notification.error({
                    message: 'Пользователь',
                    description:
                        'Ошибка, попробуйте еще раз.',
                })
            })
            .addCase(deleteCompanyUserAsync.fulfilled, (state, action) => {
                const email = action.meta.arg;
                let companyUsers = state.companyUsers.filter(
                    user => user.email !== email
                );
                state.companyUsers = companyUsers;

                notification.success({
                    message: 'Пользователь',
                    description:
                        'Пользователь удален.',
                })
            })
    }
})

export const {setCompanyDialogVisible, setCompanyUserDialogVisible} = profileSlice.actions;

export const profileMyCompanyAsync = createAsyncThunk('profile/myCompanyAsync', () => {
    return getMyCompany();
})

export const profileSaveMyCompanyAsync = createAsyncThunk('profile/saveMyCompanyAsync', (customerCompanyValues) => {
    return saveMyCompany(customerCompanyValues);
})

export const profileCompanyUsersAsync = createAsyncThunk('profile/profileCompanyUsersAsync', () => {
    return getMyCompanyUsers();
})

export const addCompanyUserAsync = createAsyncThunk('profile/addCompanyUserAsync', (companyUserValues) => {
    return addCompanyUser(companyUserValues);
})

export const deleteCompanyUserAsync = createAsyncThunk('profile/deleteCompanyUserAsync', (email) => {
    return deleteCustomerCompanyUsers(email);
})

export default profileSlice.reducer;
