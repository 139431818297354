import React from 'react'
import './FactoryViewer.less'
import {Row, Col, Divider} from "antd";

const FactoryViewer = ({factory}) => {
    const {memberId, companyName, productTags,
        isFactory, superFactory, location={}, factoryLevel, factoryAreaSize=undefined,
        serviceTags = [], shopRepurchaseRate, responseRate, tpMember, tpYear
    } = factory;
    const {city} = location;

    const factoryUrl = `/1688/factories/${memberId}`

    const factoryDetail = () => {
        const factoryStateName = superFactory ? 'суперфабрика' : isFactory ? 'фабрика' : undefined;
        return(
            <div className='factory-info'>
                <div>{city}</div>
                <div className='bold'>{factoryStateName}</div>
                <div>{factoryLevel}</div>
                {factoryAreaSize &&
                    <div>
                        Площадь {factoryAreaSize}
                    </div>
                }
            </div>
        )

    }

    return(
        <>
            <div key={memberId} className='factory-viewer-wrapper'>
                <div className='factory-content'>
                    <div className='factory-caption'>
                        <a href={factoryUrl} target='_blank' rel="noreferrer">{companyName}</a>
                    </div>
                    <div className='factory-product-tags'>
                        {productTags}
                    </div>
                    <div className='factory-detail-container'>
                        <div className='factory-detail'>
                            <h4>Услуги</h4>
                            <div>
                                {serviceTags.map(tag =>
                                    <p>{tag}</p>
                                )}
                            </div>
                        </div>

                        <div className='factory-detail'>
                            <h4>Характеристики</h4>
                            <div>
                                <p>{city}</p>
                                <p>{superFactory ? 'суперфабрика' : isFactory ? 'фабрика' : undefined}</p>
                                <p>{factoryLevel}</p>
                                {factoryAreaSize &&
                                    <p>
                                        Площадь {factoryAreaSize}
                                    </p>
                                }
                            </div>
                        </div>

                        <div className='factory-detail'>
                            <h4>Доп.инфо</h4>
                            <div>
                                <p>Повт.покупки {shopRepurchaseRate}</p>
                                <p>Процент ответов {responseRate}</p>
                                <p>TP Member {tpMember ? 'Да' : 'Нет'}</p>
                                <p>TP Year {tpYear}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FactoryViewer;

