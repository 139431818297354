import {Outlet} from "react-router-dom";
import './Account.less'

const Account = () => {

    return(
        <div className="page-template page-account">
            <div className="page-body">
                <div className="d-grid grid-1 grid-md-2">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Account;
