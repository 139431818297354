import React from 'react';
import {Button, InputNumber} from "antd";
import {Icon} from "@iconify/react";


const CartSkuAmn = ({sku = {}}) => {
    const {propsNames = '', orderedCount = 0} = sku;
    return(
        <div className='sku-prop-amn'>
            {propsNames && <div className="prop-name">{propsNames.split(';').slice(1).map(name => name)}</div>}
            <div className="amn">
                {/*<InputNumber disabled size="small" min={0} max={100000} defaultValue={orderedCount}/>*/}
                {orderedCount}
            </div>
        </div>
    )
}

const OrderProductProps = ({product ={}}) => {
    const {productDetail = {}, orderedSkus = []} = product;
    const {skuProps =[], skus = [], currency='CNY', itemId} = productDetail;

    const groupByProp = (orderedSkus, pid) => {
        return orderedSkus.reduce((accum, value) => {
            const {propsIds} = value;
            const ids = propsIds.split(';');

            const pidProp = ids.find( (props) => {
                return props.split(':')[0] == pid;
            });
            const vidId = pidProp.split(':')[1];
            const vidArray = accum[vidId] ? accum[vidId] : [];
            const groupedValuesByVid = [
                ...vidArray,
                value
            ];
            return {
                ...accum,
                [vidId] : groupedValuesByVid
            }
        }, {});

    }

    const orderedSkusRenderer = () => {
        if(skuProps.length > 0) {
            let pid = skuProps[0].pid;
            const groupedProps = groupByProp(orderedSkus, pid);
            return Object.keys(groupedProps)
                .map( key => {
                    const prop = skuProps.find((prop) => prop.pid === pid);
                    const {prop_name = '', values = []} = prop;
                    const prop_value = values.find((val) => val.vid == key);
                    const {name, imageUrl} = prop_value;
                    return(
                        <div className='sku-prop-container'>
                            <div className='sku-prop' key={key}>
                                {/*<div className='title'>{prop_name}</div>*/}
                                <div className='img-data-container'>
                                    <div className='img-data'>
                                        { imageUrl && <img src={imageUrl} alt='' /> }
                                        {<span>{name}</span>}
                                    </div>
                                </div>
                            </div>
                            {groupedProps[key]
                                .sort((a,b)=> a.propsIds < b.propsIds ? -1 : 1)
                                .map(sku => {
                                    return <CartSkuAmn sku={sku} />
                                })}
                        </div>
                    )
                })
        }
    }

    return (
        <div>
            {orderedSkusRenderer()}
        </div>
    )
}

export default OrderProductProps;
