import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Skeleton, Divider, Flex, Layout, Space } from "antd";

import './Factories1688.less';
import {factoryRequestAsync} from "./factory1688Slice"
import FactoriesView from "./FactoryView"

const { Content } = Layout;


const Factories1688 = () => {
    const {memberId} = useParams();
    const dispatch = useDispatch();

    const factory = useSelector(state => state.factory1688.factory)
    const loading = useSelector(state => state.factory1688.loading)

    useEffect(()=>{
        dispatch(factoryRequestAsync({memberId}));
    },[dispatch, memberId])

    if(!loading) {
        return (
            <Layout className='factory-page'>
                <Content className='factory-content'>
                    <Space direction='vertical'>
                        <FactoriesView factory={factory}/>
                    </Space>
                </Content>
            </Layout>
        )
    } else {
        return (
            <Skeleton active/>
        )
    }
}

export default Factories1688;
