import {createContext, useContext, useState, useEffect, useCallback} from "react";
import axios from "axios";
import {API_BASE_URL, AUTH_BASE_PATH} from "../constants";
import {useLocalStorage} from "./useLocalStorage";
import {generateCodeChallenge, generateCodeVerifier, generateState} from "./pkce";
import {decode, encode} from 'base-64';
import {useCookies} from "react-cookie";
import {authenticate} from "../services/apiService";

const AuthContext = createContext();

const getInstance = (timeout) => {
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN)
    };

    return axios.create({
        baseURL: API_BASE_URL,
        timeout: timeout,
        headers: headers,
        withCredentials: true
    });
}


const CUSTOMER_ROLE = 'CUSTOMER';
const TC_ROLE = 'TC';

function decodeJwt(token) {
    var base64Payload = token.split(".")[1];
    var payload = decodeURIComponent(
        atob(base64Payload)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(payload);
}

export const getToken = () => {
    return localStorage.getItem('token');
};

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState({});
    const [authorities, setAuthorities] = useState([]);
    const [token, setToken] = useLocalStorage("token", null);

    const isAuthenticated = () => {
        return token;
    }

    const isCustomer = () => {
        if(authorities && authorities.includes(CUSTOMER_ROLE)) {
            return true;
        }
        return false;
    }

    const isTC = () => {
        if(authorities && authorities.includes(TC_ROLE)) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if(token) {
            const {sub = 'unknown', roles = []} = decodeJwt(token);
            setUser({
                userName: sub
            });
            setAuthorities(roles);
        }
    }, [token])

    const login = async (username, password) => {
        // Отправляем запрос на сервер для получения токена
        try {
            const token = await authenticate(username, password)
            // Сохраняем токен в localStorage
            setToken(token);
            return true; // Возвращаем успех
        }
        catch( error) {
            console.error("Ошибка при авторизации:", error);
            return false; // Возвращаем неудачу
        }
    }

    const tokenRevoke = async () => {
        setToken(undefined);
        setUser({});
        setAuthorities([]);
    }

    return <AuthContext.Provider value={{ user, isAuthenticated, login, tokenRevoke, isCustomer, isTC }}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
    return useContext(AuthContext);
};
