import React, {useState, useRef, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {orderRequestAsync, changeOrderStatusAsync} from "./orderSlice";
import { Flex, Descriptions, Table, Divider, Skeleton, Space, Typography, Image, Breadcrumb, Select, notification } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

const { Text } = Typography;

const { Column, ColumnGroup } = Table;


const OrderView = () => {
    const {orderId} = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const order = useSelector(state => state.order.order)
    
    const loading = useSelector(state => state.order.loading)

    useEffect(()=>{
        dispatch(orderRequestAsync({orderId}));
    },[dispatch, orderId])


    const getOrderedSkuProps = (skuProps, orderedSku) => {
      let propPairs = orderedSku.propsIds.split(';');
      let keyValuePropPairs = propPairs.map(pair => {
        let [key, value] = pair.split(':');
        return { [key]: parseInt(value) };
      });

      const nameValuePropPairs=[]
      for (const pair of keyValuePropPairs) {
        for (const[key, value] of Object.entries(pair)){
          let foundProp = skuProps.find(prop => prop.pid === parseInt(key));    
          let foundValue = foundProp.values.find(v => v.vid === value);  
          nameValuePropPairs.push({"pid" : parseInt(foundProp.pid), "name" : foundProp.prop_name, "value" : foundValue});
        }
          
      }
      return nameValuePropPairs;
    }

    const openNotification = (placement) => {
      api.info({
        message: `Сохранено!`,
        description: 'Статус заказа изменен',
        placement,
      });
    };

    const handleChangeStatus = (status) => {
      dispatch(changeOrderStatusAsync({orderId, status})) 
      openNotification('topRight')       
    }

    const expandedRowRender = (product) => {
      const {skuProps} = product.productDetail;
      const {skus} = product.productDetail;
      
      let orderedSkus = product.orderedSkus.map(sku => {
        let nameValuePropPairs = getOrderedSkuProps(skuProps, sku);
        const skuNew = Object.assign({}, sku);
        skuNew.nameValuePropPairs=nameValuePropPairs;
        const imgProp = nameValuePropPairs.find(p => p.pid === 0);
        skuNew.imageUrl = imgProp.value.imageUrl;

        const sk = skus.find(s => s.skuid === sku.skuid);
        skuNew.sale_price = sk.sale_price;

        return skuNew;
      })

      return(
        <Table 
          rowKey="skuid" 
          dataSource={orderedSkus} 
          pagination={false} 
        >
          <Column
                    dataIndex="imageUrl"
                    key="img"
                    render={(imageUrl) => (
                      <Image
                        height={100}
                        src={imageUrl}
                      />
                    )}
          />
          <Column
                    title="Параметры товара"
                    dataIndex="nameValuePropPairs"
                    key="props"
                    render={(props) => (<Flex vertical>{props.map(prop => 
                      {return(
                        <Space><Text strong>{prop.name}</Text><Divider type="vertical"/>{prop.value.name}</Space>
                      )
                    })}</Flex>)}
          />
          <Column
                    title="Цена"
                    dataIndex="sale_price"
                    key="sale_price"
                    render={(price) => (
                      <Flex>
                        <Typography.Text>{price}</Typography.Text>
                        <Divider type='vertical'/>
                        <Typography.Text>{product.productDetail.currency}</Typography.Text>
                      </Flex>
                    )}
          />
          <Column
                    title="Количество"
                    dataIndex="orderedCount"
                    key="orderedCount"
          />
        </Table>
      )
    }

    if(!loading) {
      const {company} = order;
      const items = [
        {
          key: '1',
          label: 'Номер заказа',
          children: order.number,
        },
        {
          key: '2',
          label: 'Клиент',
          children: company.fullName,
        },
        {
          key: '3',
          label: 'Дата создания',
          children: order.created,
        },
        {
          key: '4',
          label: 'Статус',
          children: <Select
                      defaultValue={order.processStatus}
                      style={{
                        width: 220,
                      }}
                      onChange={handleChangeStatus}
                      options={[
                        {
                          value: 'NEW',
                          label: 'Новый',
                        },
                        {
                          value: 'IN_PROCEED_BY_TK',
                          label: 'В работе у транспортной компании',
                        },
                        {
                          value: 'ACCEPTED_BY_TK',
                          label: 'Принят транспортной компанией',
                        },
                        {
                          value: 'REJECTED_BY_TK',
                          label: 'Отклонен транспортной компанией',
                        },
                        {
                          value: 'ACCEPTED_BY_CUSTOMER',
                          label: 'Принят покупателем',
                        },
                        {
                          value: 'REJECTED_BY_CUSTOMER',
                          label: 'Отклонен покупателем',
                        },
                        {
                          value: 'DONE',
                          label: 'Завершен',
                        }
                      ]}
                    />,
        },
      ];
      return(
        <Flex vertical>
            <Breadcrumb
              items={[
                {
                  href: '/',
                  title: <HomeOutlined />,
                },
                {
                  href: '/cabinet/orders',
                  title: 'Заказы',
                },
                {
                  title: ':number',
                },
              ]}
              params={{
                number: order.number,
              }}
            />
            <Divider/>
            <Descriptions bordered title="Детали заказа" items={items} />
            <Divider/>
            <Table 
                rowKey="productId"
                dataSource={order.products} 
                className='product-list'
                expandable={{expandedRowRender, defaultExpandAllRows: true}}
            >
                <Column
                    title="ID товара"
                    dataIndex="productId"
                    key="id"
                />
                <Column
                    title="Наименование товара"
                    dataIndex="productDetail"
                    key="title"
                    render={(detail) => (<>{detail.title}</>)}
                />                
            </Table>
        </Flex>
      )
    } else {
      return (
          <Skeleton active/>
      )
    }
}

export default OrderView;