import {useEffect} from 'react';
import {
    Form,
    Input,
    Select,
    Modal,
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {
    addCompanyUserAsync,
    profileCustomerCompanyAsync,
    profileSaveMyCompanyAsync,
    setCompanyUserDialogVisible
} from "./profileSlice";

const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;

const oauthConfig = {
    url: process.env.REACT_APP_OAUTH_URL,
};

const CompanyUserForm = ({form, initValues}) => {

    return (
        <Form
            layout="vertical"
            form={form}
            name="companyUser"
            initialValues={
                initValues
            }
            scrollToFirstError
        >
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: 'Пожалуйста, введите email',
                    },
                ]}
            >
                <Input />
            </Form.Item>

        </Form>
    );
};

const CompanyUserDialog = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const companyUserDialogVisible = useSelector(state => state.profile.companyUserDialogVisible);

    const handleOk = () => {

        form.validateFields().then( fieldsValue => {
            let values = {
                ...fieldsValue,
            };

            dispatch(addCompanyUserAsync(values));
        });
    }

    const handleCancel = () => {
        console.log("cancel dialog ....");
        dispatch(setCompanyUserDialogVisible(false));
    }


    return(
        <>
            <div>
                <Modal
                    title="Организация"
                    style={{ top: 20}}
                    open={companyUserDialogVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    cancelText='Отмена'
                    okText='Сохранить'
                >
                    <CompanyUserForm
                        form={form}
                    />
                </Modal>
            </div>
        </>
    );
}

export default CompanyUserDialog;

