import React from 'react';
import FormWrapper from './FormWrapper'
import {Button, Flex, Form, Input, notification} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {registration} from "../../services/apiService";

const RegistryForm = ({active = true}) => {

    const navigate = useNavigate();

    const openSuccessNotification = () => {
        notification.success({
            message: 'Регистрация',
            description:
                'Вы успешно зарегистрировались в системе.',
        });
    };

    const openFailNotification = () => {
        notification.error({
            message: 'Регистрация',
            description:
                'Ошибка регистрации, попробуйте еще раз.',
        });
    };

    const onFinish = (values) => {
        const {email, password, name, surname} = values;
        registration({
            username: email,
            firstname: name,
            surname,
            password
        }).then( res => {
            navigate('/account/login')
            openSuccessNotification();
        })
        .catch(error => {
            openFailNotification();
        })
    };

    const activeRenderer = () => {
        return(
            <FormWrapper>
                <h2 className="form-title">Регистрация</h2>
                <p>Создать новый аккаунт</p>
                <Form
                    // form={form}
                    layout="vertical"
                    name="register"
                    // onFinish={onFinish}
                    initialValues={{
                    }}
                    scrollToFirstError
                    className='account-form'
                    onFinish={onFinish}
                >

                    <Form.Item
                        name="email"
                        label={
                            <span>Email</span>
                        }
                        rules={[
                            {
                                type: 'email',
                                message: 'Пожалуйста, введите валидный Email!',
                            },
                            {
                                required: true,
                                message: 'Пожалуйста, введите Email!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label={
                            <span>Имя</span>
                        }
                        rules={[
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="surname"
                        label={
                            <span>Фамилия</span>
                        }
                        rules={[
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Пароль"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary" className="btn btn-1">
                            Регистрация
                        </Button>
                    </Form.Item>
                </Form>
                {benefitRenderer()}
            </FormWrapper>
        )
    }

    function benefitRenderer() {
        return <div className="register-benefit">
            <h5 className="semi-bold">Зарегистрируйтесь и получите возможность :</h5>

            <ul className="benefit-list">
                <li>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                         viewBox="0 0 64 64">
                        <path
                            d="M59.712 20.224q0 1.408-1.024 2.432l-30.72 30.72q-1.024 0.96-2.432 0.96t-2.432-0.96l-17.792-17.792q-0.96-1.024-0.96-2.432t0.96-2.432l4.864-4.864q1.024-1.024 2.432-1.024t2.432 1.024l10.496 10.56 23.424-23.488q1.024-1.024 2.432-1.024t2.432 1.024l4.864 4.864q1.024 0.96 1.024 2.432z"></path>
                    </svg>
                    <span>Сохранять товары в избранном</span>
                </li>
                <li>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                         viewBox="0 0 64 64">
                        <path
                            d="M59.712 20.224q0 1.408-1.024 2.432l-30.72 30.72q-1.024 0.96-2.432 0.96t-2.432-0.96l-17.792-17.792q-0.96-1.024-0.96-2.432t0.96-2.432l4.864-4.864q1.024-1.024 2.432-1.024t2.432 1.024l10.496 10.56 23.424-23.488q1.024-1.024 2.432-1.024t2.432 1.024l4.864 4.864q1.024 0.96 1.024 2.432z"></path>
                    </svg>
                    <span>Оформлять заказы</span>
                </li>
                <li>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                         viewBox="0 0 64 64">
                        <path
                            d="M59.712 20.224q0 1.408-1.024 2.432l-30.72 30.72q-1.024 0.96-2.432 0.96t-2.432-0.96l-17.792-17.792q-0.96-1.024-0.96-2.432t0.96-2.432l4.864-4.864q1.024-1.024 2.432-1.024t2.432 1.024l10.496 10.56 23.424-23.488q1.024-1.024 2.432-1.024t2.432 1.024l4.864 4.864q1.024 0.96 1.024 2.432z"></path>
                    </svg>
                    <span>Отслеживать статусы заказов</span>
                </li>
            </ul>
        </div>;
    }

    const inactiveRenderer = () => {
        return(
            <FormWrapper>
                <h2 className="form-title">Регистрация</h2>
                <p>Создать новый аккаунт</p>
                <div className="account-form register-form">
                    {benefitRenderer()}
                    <Link to="/account/register" className="btn btn-1 btn-register">Регистрация</Link>
                </div>
            </FormWrapper>
        )
    }



    return (
        <>
            {active ? activeRenderer() : inactiveRenderer()}
        </>

    )

}

export default RegistryForm;
