import React from 'react'
import './OrderDetails.less'
import OrderDetailsProduct from './OrderDetailsProduct'
const OrderDetails = ({order = {}}) => {
    const {products} = order;

    return(
        <div className='order-details'>
            <div className='order-details-wrapper'>
                {products.map(product => <OrderDetailsProduct product={product} />)}
            </div>
        </div>
    )
}

export default OrderDetails;
