import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../auth/useAuth";
import {
    Button,
    Skeleton,
    Select,
    Flex,
    Space,
    Typography,
    Empty,
    Result,
    Form,
    Row,
    Col,
    Divider,
    Image,
    Table,
    Popconfirm, notification
} from "antd";
import './Profile.less';
import {
    deleteCompanyUserAsync,
    profileCompanyUsersAsync,
    profileMyCompanyAsync,
    setCompanyDialogVisible,
    setCompanyUserDialogVisible
} from "./profileSlice";
import MyCompanyForm from "./MyCompanyDialog";
import {Icon} from "@iconify/react";
import MyCompanyDialog from "./MyCompanyDialog";
import CompanyUserDialog from "./CompanyUserDialog";

const {Column} = Table;
const { Option } = Select;


const Profile = () => {
    const dispatch = useDispatch();
    const myCompany = useSelector(state => state.profile.myCompany);
    const companyUsers = useSelector(state => state.profile.companyUsers);
    const companyUsersLoading = useSelector(state => state.profile.companyUsersLoading);
    const [api, contextHolder] = notification.useNotification();

    const { isCustomer = () => {}, isTC = () => {}, isAuthenticated = () => {}} = useAuth();
    const loading = useSelector(state => state.profile.loading)

    useEffect(()=>{
        if(isAuthenticated()) {
            dispatch(profileMyCompanyAsync());
            dispatch(profileCompanyUsersAsync());
        }
    },[dispatch, isCustomer]);


    const companyRenderer = () => {
        const {shortName, fullName, inn} = myCompany;
        return(
            <div className='customer-company-container'>
                <Button type='link' onClick={() =>  dispatch(setCompanyDialogVisible(true)) }>Изменить</Button>
                <Row>
                    <Col span={3}>ИНН</Col>
                    <Col span={8}>{inn}</Col>
                </Row>
                <Row>
                    <Col span={3}>Наименование</Col>
                    <Col span={8}>{shortName}</Col>
                </Row>
                <Row>
                    <Col span={3}>Полное наименование</Col>
                    <Col span={8}>{fullName}</Col>
                </Row>
            </div>
        )
    }

    const companyUsersRenderer = () => {
        return (
            <div className='users-company-container'>
                <Button type='link' onClick={() =>  dispatch(setCompanyUserDialogVisible(true)) }>Добавить пользователя</Button>
                <Table
                    rowKey="email"
                    dataSource={companyUsers}
                    pagination={false}
                    loading={companyUsersLoading}
                    size='small'
                >
                    <Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                    />
                    <Column
                        key='action'
                        // align='center'
                        width='130px'
                        render={( record) => {
                            return(
                                <div className='ip-transactions'>
                                    <Popconfirm
                                        title='Вы уверены что хотите удалить пользователя?'
                                        onConfirm={()=>{ dispatch(deleteCompanyUserAsync(record.email)) }}
                                        okText="Да"
                                        cancelText="Нет"
                                    >

                                        <Button type='link' >
                                            <Icon fontSize={14} icon='material-symbols:delete-outline'/>
                                        </Button>
                                    </Popconfirm>
                                </div>
                            )
                        }}
                    />
                </Table>
            </div>
        )
    }

    if (isCustomer() || isTC())
        if (loading)
            return (<Skeleton active/>)
        else
            return (
                <div className='profile-page'>
                    <Divider>Моя компания</Divider>
                    {companyRenderer()}
                    <Divider>Пользователи</Divider>
                    {companyUsersRenderer()}
                    <MyCompanyDialog />
                    <CompanyUserDialog />
                </div>
            );
}

export default Profile;
