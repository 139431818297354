import React, { useState } from 'react';
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    Modal, notification,
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {setRegistrationDialogVisible} from "./registrationSlice";
import axios from "axios";

const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;

const oauthConfig = {
    url: process.env.REACT_APP_OAUTH_URL,
};

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const RegistrationForm = ({form}) => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };


    return (
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
            }}
            scrollToFirstError
        >
            <Form.Item
                name="login"
                label={
                    <span>Логин&nbsp;
                        <Tooltip title="What do you want others to call you?">
                          {/*<QuestionCircleOutlined />*/}
                        </Tooltip>
                    </span>
                }
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите логин!',
                        whitespace: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="firstName"
                label="Имя"
                rules={[
                    {
                        required: false,
                        whitespace: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="middleName"
                label="Отчество"
                rules={[
                    {
                        required: false,
                        whitespace: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="lastName"
                label="Фамилия"
                rules={[
                    {
                        required: false,
                        whitespace: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                name="password"
                label="Пароль"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Подтверждение пароля"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject('Подтвердите соглашения с условия пользования'),
                    },
                ]}
                {...tailFormItemLayout}
            >
                <Checkbox>
                    Я ознакомлен с <a href="">пользовательским соглашением</a>
                </Checkbox>
            </Form.Item>
        </Form>
    );
};

const RegistrationDialog = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const registrationDialogVisible = useSelector(state => state.registration.dialogVisible);

    const [api, contextHolder] = notification.useNotification();


    const openSuccessNotification = () => {
        api.success({
            message: 'Регистрация',
            description:
                'Вы успешно зарегистрированы, попробуйте авторизоваться',
        });
    };

    const openFailNotification = () => {
        api.error({
            message: 'Регистрация',
            description:
                'Ошибка регистрации, попробуйте еще раз.',
        });
    };

    const handleOk = () => {

        form.validateFields().then( fieldsValue => {
            let values = {
                ...fieldsValue,
            };
            axios({
                method: 'post',
                url: `${oauthConfig.url}/registration/create`,
                data: {
                    login: values.login,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    middleName: values.middleName,
                    password: values.password
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                openSuccessNotification();
            })
            .catch(response => {
                console.log(response);
                openFailNotification();
            });

            dispatch(setRegistrationDialogVisible(false));
        });
    }

    const handleCancel = () => {
        console.log("cancel dialog ....");
        dispatch(setRegistrationDialogVisible(false));
    }


    return(
        <>
            <div>
                <Modal
                    title="Регистрация"
                    style={{ top: 20}}
                    open={registrationDialogVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    cancelText='Отмена'
                    okText='Регистрация'
                >
                    <RegistrationForm form={form}/>
                </Modal>
            </div>
            {contextHolder}
        </>
    );
}

export default RegistrationDialog;

