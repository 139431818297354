import React, {useEffect} from 'react'
import CartProductProps from "./CartProductProps";


const CartProduct = ({product = {}}) => {
    const { productId, productDetail = {}, orderedSkus = []} = product;
    const fabricusUrl = `/1688/products/${productId}`;
    const {title, originalTitle, productUrl, mainImgs = []} = productDetail;
    const img = mainImgs[0];

    return(
        <div key={productId} className='cart-product-details'>
            <div className='product-img-container'>
                <div className='product-img'>
                    <a href={fabricusUrl} target='_blank' rel="noreferrer"><img src={img} /></a>
                </div>
            </div>
            <div  className='product-properties-container'>
                <h1>{title}</h1>
                { productUrl &&
                    <div className='original'>
                        <a href={productUrl} target='_blank' rel="noreferrer">{originalTitle} -&gt; 1688.com</a>
                    </div>
                }
                <CartProductProps product={product} />
            </div>
        </div>
    );
}

export default CartProduct;
