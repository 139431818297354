import { Flex, List, Typography, Table, Space, Breadcrumb, Divider } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {searchOrdersAsync} from '../CabinetSlice';
import './TcOrders.less';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;


const TcOrders = () => {

    const dispatch = useDispatch();
    const ordersResult = useSelector(state => state.cabinet.ordersResult);
    const {orders} = ordersResult;
    const [ordersSort, setOrdersSort] = useState(ordersResult.sort);
    const {pagination: { total = 0, pageSize = 20, current= 1}} = ordersResult;

    useEffect(()=>{
        dispatch(searchOrdersAsync({
            pagination: undefined,
            sort: ordersSort
        }));
    },[dispatch])



    return (
        <Flex vertical className='orders-page'>
            <Breadcrumb
              items={[
                {
                  href: '/',
                  title: <HomeOutlined />,
                },
                {
                  href: '/cabinet/orders',
                  title: 'Заказы',
                }
              ]}
            />
            <Divider/>
            <Table 
                rowKey="_id"
                dataSource={orders} 
                className='orders-list'
            >
                <Column
                    title="Номер"
                    dataIndex="number"
                    key="number"
                />
                <Column
                    title="Клиент"
                    dataIndex="company"
                    key="client"
                    render={(client) => (<>{client.shortName}</>)}
                />
                <Column
                    title="Дата создания"
                    dataIndex="created"
                    key="created"
                />
                <Column
                    title="Статус"
                    dataIndex="processStatus"
                    key="processStatus"
                />
                <Column
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <Link to={`/cabinet/order/${record.id}`}>Просмотр</Link>
                        </Space>
                    )}
                />
            </Table>;
        </Flex>
    )

}

export default TcOrders;