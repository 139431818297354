import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {order, updateOrderStatus} from "../../../services/apiService";


export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: true,
        orderId: undefined,
        order: {},
    },
    reducers: {
        setOrderStatus: (state, {payload}) => {
            state.order.processStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(orderRequestAsync.pending, (state, action) => {
                const {orderId} = action.meta.arg;
                state.loading = true;
                state.orderId = orderId;
            })
            .addCase(orderRequestAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(orderRequestAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeOrderStatusAsync.fulfilled, (state, action) => {
                state.order = action.payload;
            })
    }
})

export const orderRequestAsync = createAsyncThunk('order/orderRequestAsync',
    ({orderId}) => {
    return order(orderId);
})

export const changeOrderStatusAsync = createAsyncThunk('order/changeOrderStatusAsync',
    ({orderId, status}) => {
    return updateOrderStatus(orderId, status);
})

export default orderSlice.reducer;
