import React, { useState, useRef, useEffect } from 'react';
import ProductImageContainer from "./ProductImageContainer";

import ProductPropertiesContainer from "./ProductPropertiesContainer";
import ProductInfoContainer from "./ProductInfoContainer";
import ProductDetailsContainer from "./ProductDetailsDescription";

import { Divider, Flex, Layout, Space } from "antd";

import './Products1688.less';
import Sider from 'antd/es/layout/Sider';

const { Content } = Layout;



const Product1688Container = ({ product = {} }) => {

    return (
        <Layout hasSider className='product-page'>
            <Content className='product-content'>
                <Space direction='vertical'>
                    <Flex className='product-info'>
                        <ProductImageContainer product={product} />
                        <Divider type='vertical' />
                        <ProductPropertiesContainer product={product} />
                    </Flex>
                    <ProductDetailsContainer product={product} />
                </Space>
            </Content>
            <Sider className='product-sider'>
                <Space direction='vertical'>
                    <ProductInfoContainer product={product} />
                </Space>
            </Sider>
        </Layout>
    )

}

export default Product1688Container;
