import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Image, Pagination, Skeleton, Flex, Divider, Row} from "antd";
import ProductViewer from "./ProductViewer";
import './SearchResultContent.less'
import {searchProductsAsync} from "./search1688Slice";

const SearchProductResultContent = () => {
    const dispatch = useDispatch();
    const productsResult = useSelector(state => state.search1688.productsResult)
    const loading = useSelector(state => state.search1688.loading)
    const {sort} = productsResult;

    const productsRenderer = () => {
        const {products} = productsResult;
        return (
            <Row gutter={[16, 16]} style={{ height: '100%' }}  justify="space-around">
                {products.map(product => (
                    <ProductViewer key={product.itemId} product={product} />
                ))}
            </Row>
        )
    }

    const paginationRenderer = () => {
        const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
        if(total > pageSize) {
            return <Pagination
                // hideOnSinglePage={true}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                pageSize={pageSize}
                current={current}
                onChange={(page, pageSize) => {
                    const {keywords, originalKeywords, pagination} = productsResult;
                    const newPagination = {
                        ...pagination,
                        current: page,
                    }

                    dispatch(searchProductsAsync({keywords, originalKeywords, pagination: newPagination, sort}))
                }}
            />
        } else {
            return null;
        }
    }


    const contextRenderer = () => {
        if (!loading) {
            return (
                <Flex vertical className='f-products-content'>
                        {productsRenderer()}
                        <Divider />
                        {paginationRenderer()}
                </Flex>
            )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <Flex className='search-result-content'>
            {contextRenderer()}
        </Flex>
    )
}

export default SearchProductResultContent;
