import React from 'react';
import FormWrapper from './FormWrapper'
import {Form, Input, Tooltip, Button, Flex, notification} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../auth/useAuth";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const LoginForm = ({formType = 'active'}) => {
    const { isAuthenticated = () => {}, login = (username, password) => {} } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const openSuccessNotification = () => {
        notification.success({
            message: 'Авторизация',
            description:
                'Вы успешно авторизовались в системе.',
        });
    };

    const openFailNotification = () => {
        notification.error({
            message: 'Авторизация',
            description:
                'Ошибка авторизации, попробуйте еще раз.',
        });
    };

    const onFinish = async (values) => {
        const {email, password} = values;
        try {
            const res = await login(email, password);
            if (res) {
                navigate('/')
                openSuccessNotification();
            } else
                openFailNotification();
        }
        catch (error) {
            openFailNotification();
        }
    };

    const activeRenderer = () => {
        return (
            <FormWrapper>
                <div id="customer-login">
                    <h2 className="form-title">Вход</h2>
                    <p>Добро пожаловать! Вход в ваш аккаунт</p>
                    <Form
                        // form={form}
                        layout="vertical"
                        name="register"
                        // onFinish={onFinish}
                        initialValues={{
                        }}
                        scrollToFirstError
                        className='account-form'
                        onFinish={onFinish}
                    >

                        <Form.Item
                            name="email"
                            label={
                                <span>Email</span>
                            }
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Пожалуйста, введите валидный Email!',
                                },
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите Email!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Пароль"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item colon={false}>
                            <Flex justify="space-between">
                                <Link to='/' >
                                    Вернуться к покупкам
                                </Link>
                                <Link >
                                    Забыли пароль?
                                </Link>
                            </Flex>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="btn btn-1">
                                Логин
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </FormWrapper>
        );
    }
    const inactiveRenderer = () => {
        return (
            <FormWrapper>
                <div id="customer-login">
                    <h2 className="form-title">Вход</h2>
                    <p>Добро пожаловать! Уже есть аккаунт?</p>
                    <div className="account-form login-form">
                        <Link className='btn btn-1' to={'/account/login'} >
                            Вход
                        </Link>
                    </div>
                </div>

                {/*
            <div id="recover-password" style={{display: 'none'}}>
                <h2 className="form-title">Reset Password</h2>
                <p className="note no-active">We will send you an email to reset your password.</p>

                <form method="post" action="/account/recover" accept-charset="UTF-8"
                      className="account-form recover-form">
                    <input type="hidden" name="form_type" value="recover_customer_password"/>
                    <input type="hidden" name="utf8" value="✓"/>

                    <div className="form-group">
                        <label htmlFor="email">Email Address<span className="req">*</span></label>
                        <input type="email" value="" name="email" id="recover-email"/>
                    </div>

                    <div className="form-group-button">
                        <button className="btn btn-1" type="submit">Submit</button>
                        <a className="cancel btn btn-2" href="javascript:;"
                           onClick="hideRecoverPasswordForm();">Cancel</a>
                    </div>

                </form>

            </div>
*/}
            </FormWrapper>
        );
    }

    const renderer = () => {
        if(formType === 'active')
            return activeRenderer();
        else if(formType === 'inactive')
            return inactiveRenderer();
        return (<></>);
    }

    return (
        renderer()
    )
}

export default LoginForm;
