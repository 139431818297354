import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {addCartItem, cart, createOrder, getDeliveryCompanies } from "../../../services/apiService";

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loading: false,
        addItemStatus: 'idle',
        addItemError: null,
        itemAdding: false,
        deliveryCompanies: [],
        currDeliveryCompany: undefined,
        items: [],
        createOrderStatus: 'idle',
        createOrderError: null,
        newOrderNumber: null,
    },
    reducers: {
        setCurrentDeliveryCompany: (state, {payload}) => {
            state.currDeliveryCompany=payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCartItemRequestAsync.pending, (state, action) => {
                state.itemAdding = true;
                state.addItemStatus = 'loading';
                state.addItemError = null;
            })
            .addCase(addCartItemRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.addItemStatus = 'succeeded';
                state.items = items;
            })
            .addCase(addCartItemRequestAsync.rejected, (state, action) => {
                state.addItemStatus = 'failed';
                state.addItemError = action.payload || 'Request failed';
            })
            .addCase(deliveryCompaniesAsync.fulfilled, (state, action) => {
                const {content = []} = action.payload;
                state.deliveryCompanies = content;
            })
            .addCase(cartRequestAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(cartRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.loading = false;
                state.items = items;
            })
            .addCase(createOrderAsync.pending, (state, action) => {
                state.loading = true;
                state.createOrderStatus = 'loading';
            })
            .addCase(createOrderAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.createOrderStatus = 'succeeded';
                const {number} = action.payload;
                state.newOrderNumber = number;
                state.items = [];
            })
            .addCase(createOrderAsync.rejected, (state, action) => {
                state.loading = false;
                state.createOrderStatus = 'failed';
                state.createOrderError = action.payload || 'Request failed';
            })
    }
})

export const {setCurrentDeliveryCompany} = cartSlice.actions;

export const addCartItemRequestAsync = createAsyncThunk('cart/addCartItemRequestAsync', (cartItemSku) => {
    return addCartItem(cartItemSku);
})

export const cartRequestAsync = createAsyncThunk('cart/cartRequestAsync', () => {
    return cart();
})

export const deliveryCompaniesAsync = createAsyncThunk('cart/deliveryCompaniesAsync', () => {
    return getDeliveryCompanies();
})

export const createOrderAsync = createAsyncThunk('cart/createOrderAsync', (orderValues) => {
    return createOrder(orderValues);
})




export default cartSlice.reducer;
