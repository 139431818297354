import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {OrdersSortTypes} from "../../constants/enums";
import {searchOrders} from "../../services/apiService";


export const cabinetSlice = createSlice({
    name: 'orders',
    initialState: {
        ordersResult: {
            orders: [],
            pagination: {},
            sort: OrdersSortTypes.DEFAULT
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchOrdersAsync.pending, (state, action) => {
                const {sort} = action.meta.arg;
                state.ordersResult = {
                    ...state.ordersResult,
                    sort,
                }
                state.loading = true;
            })
            .addCase(searchOrdersAsync.fulfilled, (state, action) => {
                const {content: orders = [], pageable: page, totalElements, sort} = action.payload;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.ordersResult = {
                    ...state.ordersResult,
                    sort,
                    orders,
                    pagination
                }
                state.loading = false;
            })
            .addCase(searchOrdersAsync.rejected, (state, action) => {
                state.loading = false;
            })
    }
})

export const searchOrdersAsync = createAsyncThunk('cabinet/searchOrdersAsync',
    ({pagination, sort}) => {
    return searchOrders(pagination, sort);
})

export default cabinetSlice.reducer;