import React, {useState, useRef, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Product1688Container from './Product1688Container';
import {searchProductByUrl} from "./product1688Slice";
import {useDispatch, useSelector} from "react-redux";
import {Skeleton, Divider, Flex, Layout, Space } from "antd";

import './Products1688.less';
import {cartRequestAsync} from "../../cabinet/cart/cartSlice";


const Products1688byUrl = () => {
    const location = useLocation();
    const { productUrl } = location.state || {};

    const dispatch = useDispatch();

    const product = useSelector(state => state.product1688.product)
    const loading = useSelector(state => state.product1688.loading)

    useEffect(()=>{
        dispatch(cartRequestAsync());
        dispatch(searchProductByUrl({
            url: productUrl
        }));
    },[dispatch, productUrl])

    if(!loading) {
        return (
            <Product1688Container product={product} />
        )
    } else {
        return (
            <Skeleton active/>
        )
    }
}

export default Products1688byUrl;
