import React from 'react'
import {  Space, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import './HomeFooter.less';
import {Container} from "../../components";


const FooterBlock = ({title = '', list = []}) => {
    return(
        <div className='block-widget'>
            <div className='block-wrapper'>
                <div className='title'>
                    {title}
                </div>
                <ul className='menu-list'>
                    {list.map(item => {
                        return(
                            <li>
                                {item}
                            </li>
                        )}
                    )}
                </ul>
            </div>
        </div>
    )
}

const HomeFooter = () => {
    return(
        <div className='footer-content'>
            <Container>
                <div className='section-wrapper' >
                    <FooterBlock
                        title='Поиск'
                        list={[
                            <span>1688.com</span>
                        ]}
                    />
                    <FooterBlock
                        title='Помощь'
                        list={[
                            <span>Как заказать товар</span>,
                            <span>Условия использования</span>,
                            <span>FAQs</span>,
                            <span>Связаться в Telegram</span>
                        ]}
                    />
                    <FooterBlock
                        title='О нас'
                        list={[
                            <span>О проекте FABRIUS</span>,
                            <span>Наши контакты</span>,
                            <span>Страница VK</span>,
                            <span>Telegram</span>,
                        ]}
                    />
                    <FooterBlock
                        title='Сервисы'
                        list={[
                            <span>Транспортные компании</span>,
                            <span>Корзина</span>,
                            <span>Избранное</span>,
                        ]}
                    />
                </div>
            </Container>
        </div>
    )
}

export default HomeFooter;
