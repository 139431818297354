import  React from 'react'

import './FormWrapper.less'


const FormWrapper = ({children}) => {
    return (
        <div className='form-wrapper'>
            {children}
        </div>
    )
}

export default FormWrapper;
