import React from 'react';
import {Container} from "./index";
import {Breadcrumb} from "antd";
import {Icon} from "@iconify/react";
import {Link, useLocation, useNavigate} from "react-router-dom";


export const FBreadcrumb = () => {

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const pathnames = pathname.split("/").filter(Boolean);

    const items = pathnames.map((name, index) => {

    })

    return(

        <Container>
            <Breadcrumb className='breadcrumb' >
                {pathnames.length ? (
                    <Breadcrumb.Item href="/" >
                        <Icon fontSize={14} icon='ion:home'/>
                    </Breadcrumb.Item>
                ) : (
                    <Icon fontSize={14} icon='ion:home'/>
                )}
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1)}`;
                    const isLast = index === pathnames.length - 1;

                    return isLast ? (
                        <Breadcrumb.Item key={name}>{name}</Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item key={name} href={routeTo}>{name}</Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </Container>
    );
}
