import React from 'react';
import RegistryForm from "./RegistryForm";
import LoginForm from './LoginForm';


const RegisterPage = () => {

    return(
        <>
            <RegistryForm active={true} />
            <LoginForm formType='inactive'/>
        </>
    );
}

export default RegisterPage;
