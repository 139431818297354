import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ProductsSortTypes} from '../../../constants/enums';
import {factory1688, searchShopProducts} from "../../../services/apiService";

export const factory1688Slice = createSlice({
    name: 'factory',
    initialState: {
        loading: false,
        memberId: undefined,
        factory: {},
        productsResult: {
            keywords: "",
            originalKeywords: "",
            products: [],
            pagination: {},
            sort: ProductsSortTypes.DEFAULT
        },
        productsLoading: false
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(factoryRequestAsync.pending, (state, action) => {
                const {memberId} = action.meta.arg;
                state.loading = true;
                state.memberId = memberId;
            })
            .addCase(factoryRequestAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.factory = action.payload;
            })
            .addCase(factoryRequestAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(searchShopProductsAsync.pending, (state, action) => {
                const {memberId, sort} = action.meta.arg;
                state.productsResult = {
                    ...state.productsResult,
                    memberId,
                    sort,
                }
                state.productsLoading = true;
            })
            .addCase(searchShopProductsAsync.fulfilled, (state, action) => {
                const {memberId, products, sort} = action.payload;
                const { content: data = [], pageable: page, totalElements} = products;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.productsResult = {
                    ...state.productsResult,
                    memberId,
                    sort,
                    products: data,
                    pagination
                }
                state.productsLoading = false;
            })
            .addCase(searchShopProductsAsync.rejected, (state, action) => {
                state.productsLoading = false;
            })
    }
})

export const factoryRequestAsync = createAsyncThunk('factory/factoryRequestAsync',
    ({memberId}) => {
    return factory1688(memberId);
})

export const searchShopProductsAsync = createAsyncThunk('search/searchShopProductsAsync',
    ({memberId, pagination, sort}) => {
    return searchShopProducts(memberId, pagination, sort);
})

export default factory1688Slice.reducer;
