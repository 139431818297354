import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {productsRequestAsync, setCurrentImage, setCurrentProp} from "./product1688Slice";
import {Button, Flex, Typography, InputNumber, Descriptions, Divider, Space, Table, List, Avatar, message } from "antd";
import {useAuth} from "../../../auth/useAuth";
import {Icon} from "@iconify/react";
import {addCartItemRequestAsync} from "../../cabinet/cart/cartSlice";
import {Link} from "react-router-dom";
import './Products1688.less'

const {Text, Title} = Typography;

const SkuProps = ({product ={}}) => {
    const dispatch = useDispatch();
    const currentProps = useSelector(state => state.product1688.currentProps);
    const cartProducts = useSelector(state => state.cart.items);
    const { addItemStatus, addItemError } = useSelector(state => state.cart);

    const [itemAdding, setItemAdding] = useState(false);

    useEffect(() => {
        if (itemAdding){
            if (addItemStatus === 'loading') {
                message.loading({ content: 'Добавление в корзину...', key: 'loading' });
              } else if (addItemStatus === 'succeeded') {
                message.success({ content: 'Товар добавлен в корзину!', key: 'loading', duration: 2 });
                setItemAdding(false);
              } else if (addItemStatus === 'failed') {
                message.error({ content: `Ошибка добавления товара: ${addItemError}`, key: 'loading', duration: 2 });
                setItemAdding(false);
              }
        }
      }, [itemAdding, addItemStatus, addItemError]);

    const {skuProps =[], skus = [], currency='CNY', itemId, description} = product;
    const { isCustomer = () => {}} = useAuth();

    const orderedCartProduct = cartProducts.reduce((acc, item) => item.productId === itemId ? item: acc, {orderedSkus: []});
    const cartOrderedSkus = orderedCartProduct.orderedSkus.reduce((acc, item) =>
        {
            acc[item.skuid] = item.orderedCount;
            return acc;
        }, {})
    const [orderedSkus, setOrderedSkus] = useState(cartOrderedSkus);


    var skuPropsN = skuProps;
    if (skuProps == null) {
        skuPropsN = []
    }

    const propRenderer = (prop) => {
        const {prop_name, pid, values = []} = prop;
        const currentProp = currentProps[pid];

        const setCurrentData = (imageUrl, pid, vid) => {
            if (imageUrl)
                dispatch(setCurrentImage(imageUrl));
            dispatch(setCurrentProp({pid : pid, vid : vid}));
        }

        return(
            <Space direction='vertical' className='sku-prop' key={pid}>
                <Title>{prop_name}</Title>
                <Space className='img-data-container'>
                    {values.map((val, index) => {
                        const {name, vid, imageUrl} = val;
                        const isActive = currentProp === vid;

                        return(
                            <div className={isActive ? 'img-data active' : 'img-data'} key={vid}
                                 onClick={()=> setCurrentData(imageUrl, pid, vid)}>
                                { imageUrl && <img src={imageUrl} alt=''
                                     onClick={()=>{
                                         setCurrentData(imageUrl, pid, vid);
                                     }}
                                /> }
                                <span>{name}</span>
                            </div>
                        )
                    })}
                </Space>
            </Space>
        )
    }

    const currentPropValue = () => {
        return Object.entries(currentProps).map(( value) => {
            return value[0] + ':' + value[1];
        }).join(';');
    }

    const skuByProp = (propsIds) => {
        return skus.find(sku=> sku.props_ids === propsIds );
    }

    const addToCart = ({itemId, api, skuid, specid, propsIds, orderedCount}) => {
        if (orderedCount != undefined && orderedCount !== cartOrderedSkus[skuid]) {
            return (
                <div className='prop-data-btn'>
                    <div>
                        <Link  onClick={() => {
                                if (isCustomer() ) {
                                    setItemAdding(true);
                                    dispatch(addCartItemRequestAsync({itemId, api, skuid, specid, propsIds, orderedCount}));
                                } else {
                                    message.error('Авторизуйтесь для добавления товара в корзину!');
                                    
                                }
                            }
                        }>
                            <Icon fontSize={26} icon='tabler:shopping-cart-down' />
                        </Link>
                    </div>
                    {/*<div>*/}
                    {/*    <Link  onClick={() => {*/}
                    {/*        dispatch(addCartItemRequestAsync({itemId, api, skuid, specid, propsIds, orderedCount: 0}));}*/}
                    {/*    }>*/}
                    {/*        <Icon fontSize={26} icon='tabler:shopping-cart-x' />*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
            )
        }
    }

    const amnPropRenderer = (prop) => {
        const {prop_name, pid, values = []} = prop;
        const propValues = currentPropValue();


        return(
            <Space direction='vertical' className='sku-prop' key={pid}>
                <Title>{prop_name}</Title>
                <div className='prop-data-container'>
                    {values.map((val,idex)=>{
                        const {name, vid, imageUrl} = val;
                        const propsIds =
                            propValues ? propValues + ';' + pid + ':' + vid
                            : pid + ':' + vid;
                        const sku = skuByProp(propsIds);
                        if(sku) {
                            const {skuid, specid, sale_price, original_price, stock} = sku;
                            const price = sale_price ? sale_price : original_price;
                            // const {orderedSkus = []} = cartProduct ? cartProduct : {};
                            // const orderedSku = orderedSkus.find(item =>  item.skuid === skuid);
                            // const {orderedCount = 0} = orderedSku ? orderedSku : {};

                            return (
                                <div className='prop-data' key={vid}>
                                    <div className='prop-data-img'>
                                        {imageUrl && <img src={imageUrl} alt=''/>}
                                        <span>{name}</span>
                                    </div>
                                    <div className='prop-data-price'>{price.toLocaleString('zh-CN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: currency
                                    })}</div>
                                    <div className={`prop-data-stock ${stock > 0 ? 'in-stock' : ''}`}>
                                        {stock > 0 ? 'в наличии' : 'нет на складе'}
                                    </div>
                                    <div className='prop-data-amn'>
                                        <InputNumber size="small" min={0} max={100000} value={orderedSkus[skuid]} onChange={value =>
                                            setOrderedSkus({...orderedSkus, [skuid]: value})}/>
                                    </div>
                                    {addToCart({itemId, api: 'TMAPI', skuid, specid, propsIds, orderedCount: orderedSkus[skuid]})}
                                </div>
                            )
                        }
                    })}
                </div>
            </Space>
        )
    }


    return (
            <Space direction='vertical'>{
                skuPropsN.map((prop, index) => {
                    if(index !== skuPropsN.length-1)
                        return propRenderer(prop);
                    else
                        return amnPropRenderer(prop);
                })
            }
            </Space>
    )

}

const ProductPropertiesContainer = ({product = {}}) => {
    const {title='', skuPriceRange = {}, priceInfo = {}, currency = 'CNY',
        productUrl, originalTitle, description, domain} = product;

    var skuPriceRangeN = skuPriceRange

    if (skuPriceRangeN == null) {
        skuPriceRangeN = {};
    }

    const priceRangeRenderer = () => {
        const {sku_param = [], begin_num = 1, mixParam = {}} = skuPriceRangeN;
        const {price=0} = priceInfo;

        const priceRangeData = () => {
            if(sku_param.length > 0 ) {
                return sku_param.map((param, index) => {
                    const {price = 0, beginAmount = 0} = param;
                    return (
                        {
                            'key' : index,
                            'price' : price,
                            'beginAmount' : beginAmount
                        }
                        
                    )
                })
            } else {
                return(
                    [
                        {
                            'key' : 1,
                            'price' : price,
                            'beginAmount' : begin_num
                        } 
                    ]
            )}
        }

        const priceRangeDataSource = priceRangeData()

            
        const priceRangeColumns = [
                {
                  title: 'Цена',
                  dataIndex: 'price',
                  key: 'price',
                  align: 'right',
                  render: (number) => <Flex justify='end' className='price'>
                                        <Title strong>
                                            {number.toLocaleString('zh-CN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: currency
                                        })}
                                        </Title>
                                    </Flex>
                },
                {
                  title: 'Мин.кол-во',
                  dataIndex: 'beginAmount',
                  key: 'beginAmount',
                  align: 'right',
                  width: 150,
                  render: (number) => <Flex justify='end'>{number}</Flex>
                },
        ]
        
        return(
                <Table pagination={false} dataSource={priceRangeDataSource} columns={priceRangeColumns} />
        )
        
    }

    return(
        <Space size='large' direction='vertical' className='product-properties-container'>
            <Typography.Title>{title}</Typography.Title>

            {priceRangeRenderer()}
            
            <Typography.Paragraph>{description}</Typography.Paragraph>

            <SkuProps product={product} />
            <Flex justify='end'>
                <Link to={productUrl}>Открыть страницу товара на 1688.com</Link>
            </Flex>
        </Space>
    )
}

export default ProductPropertiesContainer;
