import React from 'react'
import './OrderDetailsProduct.less'
import CartProductProps from "../cart/CartProductProps";
import OrderProductProps from "./OrderProductProps";


const OrderDetailsProduct = ({product = {}}) => {
    const {productId, productDetail = {}} = product;
    const fabricusUrl = `/1688/products/${productId}`;
    const {title, originalTitle, productUrl, skuProps = [], mainImgs = []} = productDetail;
    const img = mainImgs[0];

    return (
        <div key={productId} className='order-product-details'>
            <div className='product-img-container'>
                <div className='product-img'>
                    <a href={fabricusUrl} target='_blank' rel="noreferrer"><img src={img}/></a>
                </div>
            </div>
            <div className='product-properties-container'>
                <h1>{title}</h1>
                {productUrl &&
                    <div className='original'>
                        <a href={productUrl} target='_blank' rel="noreferrer">{originalTitle} -&gt; 1688.com</a>
                    </div>
                }
                <OrderProductProps product={product}/>
            </div>
        </div>
    )
}

export default OrderDetailsProduct;
