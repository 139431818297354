import {configureStore} from "@reduxjs/toolkit";
import search1688Reducer from './features/1688/search/search1688Slice';
import product1688Reducer from "./features/1688/products/product1688Slice";
import factory1688Reducer from "./features/1688/factories/factory1688Slice";

import searchRusReducer from './features/rus/search/searchRusSlice';
import productRusReducer from './features/rus/products/productRusSlice';

import cabinetReducer from './features/cabinet/CabinetSlice'
import orderReducer from './features/cabinet/tcOrders/orderSlice'

import cartReducer from "./features/cabinet/cart/cartSlice";
import ordersSlice from "./features/cabinet/customerOrders/ordersSlice";
import registrationSlice from "./features/registration/registrationSlice";
import profileSlice from "./features/cabinet/profile/profileSlice";

const store = configureStore({
    reducer: {
        search1688: search1688Reducer,
        product1688: product1688Reducer,
        factory1688: factory1688Reducer,
        searchRus: searchRusReducer,
        productRus: productRusReducer,
        cabinet: cabinetReducer,
        order: orderReducer,
        cart: cartReducer,
        orders: ordersSlice,
        registration: registrationSlice,
        profile: profileSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

store.subscribe(() => {
    // @ts-ignore
})

export default store;

