import React from 'react';
import RegistryForm from "./RegistryForm";
import LoginForm from './LoginForm';


const LoginPage = () => {

    return(
        <>
            <LoginForm formType='active'/>
            <RegistryForm active={false} />
        </>
    );
}

export default LoginPage;
