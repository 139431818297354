import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {cartRequestAsync, createOrderAsync, deliveryCompaniesAsync, setCurrentDeliveryCompany} from "./cartSlice";
import {useAuth} from "../../../auth/useAuth";
import CartProduct from "./CartProduct";
import {Button, Skeleton, Select, Flex, Space, Typography, Empty, Result } from "antd";
import './CartProduct.less';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;


const Cart = () => {
    const dispatch = useDispatch();
    const { isCustomer = () => {}} = useAuth();
    const loading = useSelector(state => state.cart.loading)
    const navigate = useNavigate();

    useEffect(()=>{
        if(isCustomer()) {
            dispatch(cartRequestAsync());
            dispatch(deliveryCompaniesAsync())
        }
    },[dispatch, isCustomer]);


    const products = useSelector(state => state.cart.items);
    const deliveryCompanies = useSelector(state => state.cart.deliveryCompanies);
    const currDeliveryCompany = useSelector(state => state.cart.currDeliveryCompany);

    const handleCreateOrderButtonClick = () => {
        dispatch(createOrderAsync({deliveryCompanyId: currDeliveryCompany}));
        navigate('/cabinet/cart/createOrderResult');
    }

    const productsRender = () => {
        if(products.length > 0) {
            return (
                <Flex className='cart-page'>
                    {   
                        products.map((product, key) => (
                            <CartProduct key={key} product={product}/>
                        ))
                    }
                    <Space direction='vertical' className='order-container'>
                        <Typography.Title>Транспортная компания</Typography.Title>
                        <Select style={{width: '100%'}}
                                    placeholder={'Выберите транспортную компанию'}
                                    value={currDeliveryCompany}
                                    onChange={(value) => dispatch(setCurrentDeliveryCompany(value))}
                            >
                                {deliveryCompanies.map( type => <Option key={type.id}>{type.shortName}</Option> )}
                        </Select>
                        <Button type="primary"
                                onClick={handleCreateOrderButtonClick}
                            >
                                Заказать
                        </Button>
                    </Space>
                </Flex>
                
            )
        } else {
            return (
                <Empty 
                    description={
                        <Typography.Text>
                            Нет товаров в корзине
                        </Typography.Text>
                  }
                />
            )
        }
    }

    if (isCustomer())
        if (loading)
            return (<Skeleton active/>)
        else
            return (
                productsRender()
            );
}

export default Cart;
