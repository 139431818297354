import {useEffect} from 'react';
import {
    Form,
    Input,
    Select,
    Modal,
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {
    profileCustomerCompanyAsync,
    profileSaveMyCompanyAsync,
    setCompanyDialogVisible
} from "./profileSlice";
import {useAuth} from "../../../auth/useAuth";

const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;

const oauthConfig = {
    url: process.env.REACT_APP_OAUTH_URL,
};

const MyCompanyForm = ({form, initValues}) => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            name="mycompany"
            onFinish={onFinish}
            initialValues={
                initValues
            }
            scrollToFirstError
        >
            <Form.Item
                name="inn"
                label="ИНН"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите ИНН',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="shortName"
                label="Наименование"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите наименование',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="fullName"
                label="Полное наименование"
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите наименование',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
};

const MyCompanyDialog = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const companyDialogVisible = useSelector(state => state.profile.companyDialogVisible);
    const myCompany = useSelector(state => state.profile.myCompany);

    const handleOk = () => {

        form.validateFields().then( fieldsValue => {
            let values = {
                ...fieldsValue,
            };

            dispatch(profileSaveMyCompanyAsync(values));
        });
    }

    const handleCancel = () => {
        console.log("cancel dialog ....");
        dispatch(setCompanyDialogVisible(false));
    }


    return(
        <>
            <div>
                <Modal
                    title="Организация"
                    style={{ top: 20}}
                    open={companyDialogVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    cancelText='Отмена'
                    okText='Сохранить'
                >
                    <MyCompanyForm
                        form={form}
                        initValues={myCompany}
                    />
                </Modal>
            </div>
        </>
    );
}

export default MyCompanyDialog;

