import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../auth/useAuth";
import {Link} from "react-router-dom";
import {InputNumber} from "antd";
import {addCartItemRequestAsync} from "./cartSlice";
import {Icon} from "@iconify/react";



const CartSkuAmn = ({productId, provider, sku = {}}) => {
    const dispatch = useDispatch();
    const {propsNames = '', orderedCount = 0, skuid, specid, propsIds} = sku;
    const [orderedAmn, setOrderedAmn] = useState(orderedCount);


    const saveRenderer = () => {
        if ( orderedAmn && orderedAmn !== orderedCount) {
        return(
            <div className="save">
                <Link onClick={() =>
                    dispatch(addCartItemRequestAsync({itemId: productId,
                        api : provider, skuid, specid, propsIds, orderedCount: orderedAmn}))
                }>
                    <Icon fontSize={26} icon='tabler:shopping-cart-down' />
                </Link>
            </div>
        )}
    }

    return(
        <div className='sku-prop-amn'>
            <div className="prop-name">{propsNames.split(';').slice(1).map(name => name)}</div>
            <div className="amn">
                <InputNumber size="small" min={0} max={100000}
                             value={orderedAmn}
                             onChange={value => setOrderedAmn(value)}/>
            </div>
            {saveRenderer()}
        </div>
    )
}

const CartProductProps = ({product ={}}) => {
    const {productDetail = {}, orderedSkus = [], productId, provider} = product;
    const {skuProps =[], skus = [], currency='CNY', itemId} = productDetail;
    const { isCustomer = () => {}} = useAuth();

    const skuByProp = (propsIds) => {
        return skus.find(sku=> sku.props_ids === propsIds );
    }

    const groupByProp = (orderedSkus, pid) => {
        return orderedSkus.reduce((accum, value) => {
            const {propsIds} = value;
            const ids = propsIds.split(';');

            const pidProp = ids.find( (props) => {
                return props.split(':')[0] == pid;
            });
            const vidId = pidProp.split(':')[1];
            const vidArray = accum[vidId] ? accum[vidId] : [];
            const groupedValuesByVid = [
                ...vidArray,
                value
            ];
            return {
                ...accum,
                [vidId] : groupedValuesByVid
            }
        }, {});

    }

    const orderedSkusRenderer = () => {
        if(skuProps.length > 0) {
            let pid = skuProps[0].pid;
            const groupedProps = groupByProp(orderedSkus, pid);
            return Object.keys(groupedProps)
                .map( key => {
                    const prop = skuProps.find((prop) => prop.pid === pid);
                    const {prop_name = '', values = []} = prop;
                    const prop_value = values.find((val) => val.vid == key);
                    const {name, imageUrl} = prop_value;
                    return(
                        <>
                            <div className='sku-prop' key={key}>
                                {/*<div className='title'>{prop_name}</div>*/}
                                <div className='img-data-container'>
                                    <div className='img-data'>
                                        { imageUrl && <img src={imageUrl} alt='' /> }
                                        {<span>{name}</span>}
                                    </div>
                                </div>
                            </div>
                            {groupedProps[key]
                                .sort((a,b)=> a.propsIds < b.propsIds ? -1 : 1)
                                .map(sku => {
                                    return <CartSkuAmn productId={productId} provider={provider} sku={sku} />
                                })}
                        </>
                    )
                })
        }
    }

    return (
        <div>
            {orderedSkusRenderer()}
        </div>
    )
}

export default CartProductProps;
