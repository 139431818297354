import React, {useState, useRef, useEffect} from 'react';
import {Descriptions, Flex, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {productDetailRequestAsync} from "./product1688Slice";

const MyIframeComponent = ({detailUrl = ''}) => {
    const iframeRef = useRef(null);

    const handleIframeLoad = () => { const iframeContent = iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
        const contentHeight = iframeContent.body.scrollHeight;
        const contentWidth = iframeContent.body.scrollWidth;

        iframeRef.current.style.height = `${contentHeight}px`;
        iframeRef.current.style.width = `${contentWidth}px`;
    }

    return (
        <div className='detail'>
            <iframe ref={iframeRef} src={detailUrl} onLoad={handleIframeLoad} title="Your Iframe" />
        </div>
    );
};

const ProductDetailsDescription = ({product = {}}) => {
    const {productProps = [], detailUrl} = product;

    const dispatch = useDispatch();

    const detail = useSelector(state => state.product1688.detail)

    useEffect(()=>{
        dispatch(productDetailRequestAsync({detailUrl}));
    },[dispatch, detailUrl])

    const characteristicsRender = () => {
        const getPropItems = () => {
            return productProps.map((prop, index) => {
                const label = Object.keys(prop)[0];
                const children = prop[label]; 
                return(
                    {
                        'key': index,
                        'label': label,
                        'children': children
                    }
                )
            })
        }
        const propItems = getPropItems();
        return(
            <Descriptions bordered title="Параметры товара" items={propItems} />
        )
    }



    const detailIFrameRendrer = () => {

        try {

            var jsonString = detail.replace('var offer_details=', '');
            if (jsonString.endsWith(";")) {
                jsonString = jsonString.slice(0, -1);
            }

            if(jsonString && jsonString.length > 0) {
                var offer_details = JSON.parse(jsonString);
            
                return (
                    <div 
                        dangerouslySetInnerHTML={{ __html: offer_details.content }} 
                    />
                );
            } else {
                return null;
            }
        } catch (err){
            console.error(err);
        }
        

    }


    return(
        <Space direction='vertical' className='product-detail-container'>
            {characteristicsRender()}
            {detailIFrameRendrer()}
        </Space>
    );
}

export default ProductDetailsDescription;
