import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../auth/useAuth";
import {Skeleton, Table} from "antd";
import './Orders.less';
import {ordersAsync} from "./ordersSlice";
import dayjs from "dayjs";
import OrderDetails from "./OrderDetails";
import {OrderPaymentStatusNames, OrderStatusNames} from "../../../constants/enums";


const columns = [
    {
        title:
            <React.Fragment>
                <div>Номер</div>
            </React.Fragment>
        , dataIndex: 'number'
        , render: (text, record) => {
            return (
                <React.Fragment>
                    <div>
                        {record.number}
                    </div>
                </React.Fragment>)
        }
    }
    , {
        title:
            <React.Fragment>
                <div>Дата</div>
            </React.Fragment>
        , dataIndex: 'created'
        , render: (text, record) => {
            return (
                <React.Fragment>
                    <div>{dayjs( record.created).format('YYYY-MM-DD HH:mm')}</div>
                </React.Fragment>);
        }
        // , sorter: true
        // , defaultSortOrder: 'descent'
    }
    , {
        title:
            <React.Fragment>
                <div>Автор</div>
            </React.Fragment>
        , dataIndex: 'createdBy'
        , render: (text, record) => {
            return (
                <React.Fragment>
                    <div>{record.createdBy}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
    , {
        title:
            <React.Fragment>
                <div>Транспортная компания</div>
            </React.Fragment>
        , dataIndex: 'deliveryCompany'
        , render: (text, record) => {
            const {shortName='Не определена'} = record.deliveryCompany;
            return (
                <React.Fragment>
                    <div>{shortName}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
    , {
        title:
            <React.Fragment>
                <div>Сумма заказа</div>
            </React.Fragment>
        , dataIndex: 'orderAmount'
        , render: (text, record) => {
            return (
                <React.Fragment>
                    <div>{record.orderAmount}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
    , {
        title:
            <React.Fragment>
                <div>Цена доставки</div>
            </React.Fragment>
        , dataIndex: 'deliveryCost'
        , render: (text, record) => {
            return (
                <React.Fragment>
                    <div>{record.deliveryCost}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
    , {
        title:
            <React.Fragment>
                <div>Статус заказа</div>
            </React.Fragment>
        , dataIndex: 'processStatus'
        , render: (text, record) => {
            const statusName = OrderStatusNames[record.processStatus];
            return (
                <React.Fragment>
                    <div>{statusName}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
    , {
        title:
            <React.Fragment>
                <div>Статус оплаты</div>
            </React.Fragment>
        , dataIndex: 'paymentStatus'
        , render: (text, record) => {
            const paymentStatusName = OrderPaymentStatusNames[record.paymentStatus];
            return (
                <React.Fragment>
                    <div>{paymentStatusName}</div>
                </React.Fragment>
            )
        }
        // , sorter: true
    }
];


const CustomerOrders = () => {
    const dispatch = useDispatch();
    const { isCustomer = () => {}} = useAuth();
    const loading = useSelector(state => state.orders.loading)
    const orders = useSelector(state => state.orders.orders);
    const pagination = useSelector(state => state.orders.pagination);
    const sorting = useSelector(state => state.orders.sorting);

    useEffect(()=>{
        if(isCustomer()) {
            dispatch(ordersAsync({pagination, sorting}))
        }
    }, [dispatch, isCustomer]);

    if (isCustomer())
        return (
            <div className='orders-page'>
                <Table
                    columns={columns}
                    rowKey='id'
                    dataSource={orders}
                    loading={loading}
                    pagination={pagination}
                    size="small"
                    bordered
                    onChange={( pagination, tableFilters, sorter) => {
                        const {field, order} = sorter;
                        const sort = { field, order };
                        dispatch(ordersAsync({pagination, sorter}));
                    }}
                    expandable={{
                        expandedRowRender: (order) => <OrderDetails order={order} />,
                    }}
                />
            </div>
        );
}

export default CustomerOrders;
