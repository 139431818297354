import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Product1688Container from './Product1688Container';
import {productsRequestAsync} from "./product1688Slice";
import {useDispatch, useSelector} from "react-redux";
import {Skeleton, Divider, Flex, Layout, Space } from "antd";

import './Products1688.less';
import {cartRequestAsync} from "../../cabinet/cart/cartSlice";
import Sider from 'antd/es/layout/Sider';
import Title from 'antd/es/skeleton/Title';

const { Content } = Layout;



const Products1688 = () => {
    const {productId} = useParams();
    const dispatch = useDispatch();

    const product = useSelector(state => state.product1688.product)
    const loading = useSelector(state => state.product1688.loading)

    useEffect(()=>{
        dispatch(cartRequestAsync());
        dispatch(productsRequestAsync({productId}));
    },[dispatch, productId])

    if(!loading) {
        return (
            <Product1688Container product={product} />
        )
    } else {
        return (
            <Skeleton active/>
        )
    }
}

export default Products1688;
