import {createSlice} from '@reduxjs/toolkit'

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: {
        dialogVisible: false,
    },
    reducers: {
        setRegistrationDialogVisible: (state, {payload}) => {
            state.dialogVisible = payload;
        },
    },
})

export const {setRegistrationDialogVisible} = registrationSlice.actions;

export default registrationSlice.reducer;
