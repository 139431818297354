import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Result, Skeleton, Space } from 'antd';
import { Link } from 'react-router-dom';
import './CreateOrderResult.less';

const CreateOrderResult = () => {

    const { createOrderStatus, createOrderError, newOrderNumber } = useSelector(state => state.cart);

    if(createOrderStatus === 'succeeded'){
        return (
            <Space direction='vertical' className='create-order-result'>
                <Result status="success" title={'Создан заказ под номером '+newOrderNumber}/>
                <Link to={'/cabinet/orders'}>Все заказы</Link>
            </Space>
        )
    } else if (createOrderStatus === 'loading'){
        return (
            <Skeleton />
        )
    } else if (createOrderStatus === 'failed'){
        return (
            <Result status="error" title='Ошибка создания заказа'/>
        )
    }
}
export default CreateOrderResult;