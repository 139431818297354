import React, { useState } from 'react';
import { Dropdown, Space, Badge } from 'antd';
import {
    ShoppingCartOutlined,
    TruckOutlined,
    LogoutOutlined,
    LoginOutlined,
    UserAddOutlined,
    UserOutlined
} from '@ant-design/icons';

import {useDispatch} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";
import './HomeHeader.less';
import {setRegistrationDialogVisible} from "../registration/registrationSlice";
import RegistrationDialog from "../registration/RegistrationForm";
import './HeaderActionList.less';
import {Icon} from "@iconify/react";


const HeaderActions = ({actions = []}) => {
    return(
        <div className='header-action-list'>
            {actions.map( action => {
                return(
                    <Link to={action.link} className={`header-action-item ${action.className}`}>
                        {action.context}
                    </Link>
                );
            })}
        </div>
    )
}


const HeaderActionList = () => {

    const navigate = useNavigate();
    const [current, setCurrent] = useState('header');

    const { isAuthenticated = () => { }, isCustomer = () => { }, isTC = () => { }, codeRequestUrl = () => { }, tokenRevoke = () => { }, user = {} } = useAuth();
    const dispatch = useDispatch();

    const onClick = ({ item, key, keyPath, domEvent }) => {
        switch (key) {
            case 'exit' : tokenRevoke();break;
        }
    };

    const items =  [
        {
            label:
                <Link to='/cabinet/profile'>
                    Моя организация
                </Link>,
            key: 'company',
        },
        {
            label: 'Выйти',
            key: 'exit',
        },
    ];

    const handleLogin = async () => {
        try {
            const url = await codeRequestUrl();
            window.location.replace(url);
        }
        catch (err) {
            console.log('Ohhhh nooo!');
            console.log(err);
            tokenRevoke();
        }
    }

    const handleLogout = async () => {
        tokenRevoke();
    }

    const handleRegistration = () => {
        dispatch(setRegistrationDialogVisible(true));
    }

    const sellerHomeMenuRenderer = () => {
        return (
            <HeaderActions
                actions={[
                    {
                        link: "/cabinet/wishlist",
                        context: <Icon fontSize={32} icon='solar:heart-linear' className='icon'/>
                    }
                    , {
                        className: 'cart',
                        link: "/cabinet/cart",
                        context: <>
                            <div className='icon'>
                                <Icon fontSize={32} icon='ph:bag-light'/>
                                <div className='number'>15</div>
                            </div>
                            <div>0 руб</div>
                        </>
                    }
                ]}
            />)

                {/*<Link to="/cabinet/orders" className='header-action-item'>*/}
                {/*    <TruckOutlined className='home-header-menu-item-icon' />*/}
                {/*</Link>*/}

                {/*<Dropdown menu={{items, onClick}} >*/}
                {/*    <Link href='#'  className='header-action'>*/}
                {/*        <UserOutlined className='home-header-menu-item-icon'/>*/}
                {/*    </Link>*/}
                {/*</Dropdown>*/}
    }

    const tcHomeMenuRenderer = () => {
        return (
            <Space>
                <Link to="/cabinet/orders" className='home-header-menu-item'>
                    <Space size={0}>
                        <TruckOutlined className='home-header-menu-item-icon' />
                        Заказы
                    </Space>
                </Link>

                <Dropdown menu={{items, onClick}} >
                    <Link href='#'  className='home-header-menu-item'>
                        <Space size={0}>
                            <UserOutlined className='home-header-menu-item-icon'/>
                            Профиль
                        </Space>
                    </Link>
                </Dropdown>
            </Space>
        )
    }


    return (
        sellerHomeMenuRenderer()
    );
}

export default HeaderActionList;
