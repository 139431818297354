import {React, useRef, useEffect} from 'react'
import {useAuth} from "./useAuth";
import {useNavigate} from "react-router-dom";

const AuthCallback = () => {
    const called = useRef(false)
    const { isAuthenticated = () => {}, tokenRequest = () => {}, tokenRevoke = () => {} } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!isAuthenticated()) {
                try {
                    if (called.current) return // prevent rerender caused by StrictMode
                    called.current = true
                    const code = new URLSearchParams(window.location.search).get("code");

                    const res = await tokenRequest(code);
                    console.log('response: ', res)
                    //checkLoginState()
                    navigate('/')
                } catch (err) {
                    console.error(err)
                    tokenRevoke();
                    navigate('/');
                }
            }
            else
                navigate('/');
        })()
    }, [isAuthenticated, tokenRequest, navigate])
    return <></>
}

export default AuthCallback;
