import {useDispatch, useSelector} from "react-redux";
import {setCurrentImage} from "./product1688Slice";
import { Divider, Flex, Image, Space, Carousel } from 'antd';
import {
    DownOutlined
} from '@ant-design/icons';
import './ProductImageContainer.less'

const ProductImageSlider = ({imgs = [], onClick = (image) => {}}) => {
    return(
            <Carousel
                vertical={true}
                slidesToShow={5}
                arrows={true}
                dots={true}
                infinite={false}
                dotPosition="left"
                swipe={true}
                swipeToSlide={true}
                draggable={true}
                nextArrow={<DownOutlined />}
                prevArrow={<DownOutlined />}
                customPaging={({i}) => {
                    return(
                        <a>Hello</a>
                    )
                }}
                >
                {imgs.map((image, index) => (
                    <div>
                        <Image key={index} width={100} src={image} onClick={() => onClick(image)} preview={false}/>
                    </div>
                ))}
            </Carousel>
    )
}


const ProductImageContainer = ({product = {}}) => {
    const dispatch = useDispatch();
    const currentImage = useSelector(state => state.product1688.currentImage);
    const {mainImgs : imgs = []} = product;

    return(
        <Space className='product-img-container'>
            <ProductImageSlider
                imgs={imgs}
                onClick={(img) => {
                    dispatch(setCurrentImage(img))
                }}
            />
            <Image width={300} src={currentImage} />
        </Space>
    )
}

export default ProductImageContainer;
