import { useAuth } from "../../auth/useAuth";
import CustomerOrders from './customerOrders/CustomerOrders';
import TcOrders from './tcOrders/TcOrders';


const Orders = () => {

    const { isCustomer = () => { }, isTC = () => { } } = useAuth();

    if (isCustomer()) {
        return  (
            <CustomerOrders />
        )
    } else if (isTC()) {
        return  (
            <TcOrders />
        )
    } else {
        return null;
    }

}

export default Orders;