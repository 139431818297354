import {Outlet} from "react-router-dom";

const Cabinet = () => {

    return(

        <Outlet />

    );
}

export default Cabinet;